<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const props = defineProps({
  label: null,
  class: null,
  type: null,
  disabled: { type: Boolean }
})

const emit = defineEmits(['click'])

function onClick(event: Event) {
  if (!props.type || props.type === 'button')
    event.preventDefault()
  emit('click')
}
</script>

<template>
  <button :disabled="!!props.disabled" :class="twMerge(props.class, props.disabled ? 'bg-neutral hover:bg-neutral' : '')" class="btn" :aria-label="props.label" :type="type" @click="onClick">
    <slot />
  </button>
</template>
