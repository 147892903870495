<script setup lang="ts">
const footer = ref<null | HTMLElement>(null)
const { height } = useElementBounding(footer)
</script>

<template>
  <IonContent>
    <main
      no-border :style="{
        marginBottom: `-${height}px`,
        minHeight: '100%',
      }"
    >
      <div class="p-3">
        <Header />
        <RouterView />
      </div>
      <div
        :style="{
          height: `${height}px`,
        }"
      />
    </main>

    <Footer ref="footer" class="" />
  </IonContent>
</template>
