import Vue3Toasity, { type ToastContainerOptions, toast } from 'vue3-toastify'
import type { UserModule } from '~/types'
import 'vue3-toastify/dist/index.css'

export const install: UserModule = ({ app }) => {
  app.use(Vue3Toasity,
    {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastContainerOptions)
}
