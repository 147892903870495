<script setup lang="ts">
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
import type { StyleValue } from 'vue'
import type { ClassValue } from '~/types'
import { toNumber } from '~/util/toNumber'

const props = defineProps({
  preventClose: { type: Boolean, default: false },
  errorMessage: null,
  title: null,
  class: null,
  style: null,
  fitContent: { type: Boolean },
  contentClass: null,
  show: { type: Boolean },
  scrollX: null,
  scrollY: null
})
const emit = defineEmits(["close", "update:show", "update:scrollX", "update:scrollY"])

const { show, scrollX: vModelScrollX, scrollY: vModelScrollY } = __MACROS_useVModel("show", "scrollX", "scrollY")

const contentContainer = ref<HTMLDivElement>()

const { x: scrollX, y: scrollY } = useScroll(contentContainer)

watch(scrollX, () => vModelScrollX.value = scrollX.value)
watch(scrollY, () => vModelScrollY.value = scrollY.value)

watch(vModelScrollX, () => vModelScrollX.value = toNumber(scrollX.value, 0))
watch(vModelScrollY, () => {
  vModelScrollY.value = toNumber(scrollY.value, 0)
})

const scrollLock = useScrollLock(document.body)

watchEffect(() => {
  scrollLock.value = show.value
})

function onClickOutside() {
  if (!props.preventClose) {
    show.value = false
    emit('close')
  }
}

function onClickClose() {
  if (!props.preventClose) {
    show.value = false
    emit('close')
  }
  else if (props.errorMessage) {
    toast.error(toValue(props.errorMessage), { position: 'bottom-right' })
  }
}
const modalClasses = useMergedClasses(
  'fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-1000 p-3 bg-white drop-shadow h-[100dvh] sm:h-auto max-h-[100dvh] sm:max-h-90vh md:max-h-80vh flex flex-col max-w-[1000px]',
  computed(() => ({
    'w-fit': !!props.fitContent,
    'w-screen sm:w-90vw md:w-70vw': !props.fitContent,
  })),
  computed(() => props.class),
)

const contentClasses = useMergedClasses(
  'overflow-auto flex-grow-1 flex-shrink-1 flex-basis-auto',
  computed(() => props.contentClass ?? ''),
)
useEventListener('keyup', (event) => {
  if (show.value && event.key === 'Escape')
    show.value = false
})
</script>

<template>
  <Teleport to="body">
    <Transition
      enter-from-class="opacity-0" leave-to-class="opacity-0" enter-active-class="transition duration-300"
      leave-active-class="transition duration-300"
    >
      <div
        v-show="show" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-1000"
        @click="onClickOutside"
      />
    </Transition>
    <Transition
      enter-from-class="opacity-0" leave-to-class="opacity-0" enter-active-class="transition duration-300"
      leave-active-class="transition duration-300"
    >
      <div v-show="show" :class="modalClasses" :style="props.style">
        <div class="flex-grow-0 flex-shrink-0 flex-basis-auto">
          <div class="flex justify-between items-center">
            <div>
              <h3 v-if="props.title" class="mb-3 text-xl text-primary font-bold">
                {{ props.title }}
              </h3>
            </div>
            <slot name="header" />
            <div>
              <button class="border-none outline-none hover:text-primary" @click="onClickClose">
                <div class="i-mdi-close text-2xl" />
              </button>
            </div>
          </div>
          <slot name="header:after" />
        </div>
        <div ref="contentContainer" :class="contentClasses">
          <slot />
        </div>
        <div>
          <slot name="footer" />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
