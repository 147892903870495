<script setup lang="ts">
const emit = defineEmits(["click"])
const { t } = useI18n()
</script>

<template>
  <IonRow>
    <IonCol class="menu-col">
      <IonList lines="none">
        <IonItem>
          <RouterLink to="/price" @click="emit('click')">
            <div class="i-ion-pricetag text-primary text-2xl m-2" /> {{ t('price_query') }}
          </RouterLink>
        </IonItem>
        <IonItem>
          <RouterLink to="/account/favorites" @click="emit('click')">
            <div class="i-ion-star text-primary text-2xl m-2" /> {{ t('favorites') }}
          </RouterLink>
        </IonItem>
        <IonItem>
          <RouterLink to="/wishlists" @click="emit('click')">
            <div class="i-fa6-solid-list-check text-primary text-2xl m-2" /> {{ t('wishlists') }}
          </RouterLink>
        </IonItem>
        <IonItem>
          <RouterLink to="/account/orders" @click="emit('click')">
            <div class="i-ion-cart text-primary text-2xl m-2" /> {{ t('orders') }}
          </RouterLink>
        </IonItem>
        <IonItem>
          <!-- <RouterLink to="/object" @click="emit('click')">
            <div class="i-ion-menu text-primary text-2xl m-2" /> {{ t('objects') }}
          </RouterLink> -->
        </IonItem>
      </IonList>
    </IonCol>
  </IonRow>
</template>

<style lang="scss" scoped>
.menu-col{
  margin-top: 50px;
    ion-list{
        display: flex;
        flex-direction: column;
        align-items: start;
        ion-item{
            display: flex;
            align-items: center;
            vertical-align: middle;
            padding: 10px;
            -moz-user-select: -moz-none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            a{
                  display: flex;
                  align-items: center;
                  ion-icon{
                    margin-right: 10px;
                    --ion-color-base: var(--primary);
                }
            }
            router-link{
                display: flex;
                align-items: center;
                font-size: 1.2rem;
                color: var(--primary);
                font-weight: 700;

            }
        }
    }
}
</style>
