<script setup lang="ts">
import { getFaq } from '~/api-services/services'

const { t } = useI18n()
const showFaq = ref(false)
const faqPath = ref('')
async function openFaq() {
  if (!faqPath.value) {
    const file: any = await getFaq({
      responseType: 'blob',
    })
    const url = URL.createObjectURL(file)
    faqPath.value = url
  }
  showFaq.value = true
}
const userStore = useUser()

function openCCM() {
  (window as any).CCM.openWidget()
}
</script>

<template>
  <div>
    <PdfViewer v-model:show="showFaq" lazy :file="faqPath" class="max-w-[700px]" />
    <IonFooter class="ion-no-border">
      <IonToolbar class="footer">
        <div class="container py-5">
          <IonGrid>
            <h1>{{ t('legal') }}</h1>
            <IonRow>
              <IonCol v-if="userStore.hasUser" size-xl="2" size-lg="3" size-md="6" size-xs="6">
                <h2>
                  <button class="border-none" @click.prevent="openFaq">
                    {{ t('FAQ') }}
                  </button>
                </h2>
              </IonCol>
              <IonCol size-xl="2" size-lg="2" size-md="6" size-xs="6">
                <h2>
                  <RouterLink to="/legal/conditions">
                    {{ t('Conditions') }}
                  </RouterLink>
                </h2>
              </IonCol>
              <IonCol size-xl="2" size-lg="2" size-md="6" size-xs="6">
                <h2>
                  <RouterLink to="/legal/imprint">
                    {{ t('imprint') }}
                  </RouterLink>
                </h2>
              </IonCol>
              <IonCol size-xl="2" size-lg="2" size-md="4" size-xs="6">
                <h2>
                  <RouterLink to="/legal/data_protection">
                    {{ t('privacy') }}
                  </RouterLink>
                </h2>
              </IonCol>
              <IonCol size-xl="2" size-lg="2" size-md="4" size-xs="6">
                <h2>
                  <button class="outline-none" @click.prevent="openCCM">
                    {{ t('cookie') }}
                  </button>
                </h2>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonToolbar>
    </IonFooter>
  </div>
</template>

<style lang="scss" scoped>
ion-footer {

  h1 {
    color: var(--primary);
    font-size: 22px;
    font-weight: bold;
  }

  .footer {
    border-top: 2px solid var(--primary);
    // position: absolute;
    bottom: 0;
    padding: 0 15px 0 15px;
    --background: var(--secondary);
  }

  .container {
    margin: auto;
  }
}
</style>
