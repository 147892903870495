<script setup lang="ts">
const props = defineProps({
  to: null,
  label: null,
  iconClass: null
})
</script>

<template>
  <IonItem lines="none" class="group text-primary hover:text-primary_dark flex items-center align-middle p-3 cursor-pointer border-transparent">
    <RouterLink :to="props.to" class="flex items-center text-xl font-bold">
      <div :class="props.iconClass" class="text-2xl m-2 group-hover:scale-105 transition-transform duration-40" />
      <span class="itc-bauhaus">{{ props.label }}</span>
    </RouterLink>
  </IonItem>
</template>
