<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import headerImageSrc from '../../assets/Banner.jpg'
import headerImageSrcset from '../../assets/Banner.jpg?srcset'

// @ts-expect-error no typings
import { height as headerImageHeight, width as headerImageWidth } from '../../assets/Banner.jpg?as=meta:height;width'
import headerImageSmallSrc from '../../assets/Banner_small.jpg'
import headerImageSmallSrcset from '../../assets/Banner_small.jpg?srcset'

// @ts-expect-error no typings
import { height as headerImageSmallHeight, width as headerImageSmallWidth } from '../../assets/Banner_small.jpg?as=meta:height;width'

const breakpoints = useBreakpoints(breakpointsTailwind)

const mdAndLarger = breakpoints.greaterOrEqual('md')

const { t } = useI18n()
</script>

<template>
  <div v-if="!mdAndLarger" class="mb-4 md:mb-5 md:mx-5 md:hidden">
    <picture class="w-full h-auto block md:block">
      <source v-for="s in headerImageSmallSrcset.sources" :key="s.srcset" :type="s.type" :srcset="s.srcset">
      <img class="pointer-events-none" :src="headerImageSmallSrc" alt="" :height="headerImageSmallHeight" :width="headerImageSmallWidth">
    </picture>
  </div>
  <div class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-y-4 gap-x-2 md:gap-y-7 md:gap-x-7 mx-0 md:mx-5">
    <HomeCard to="/price" :label="t('price_query')" icon="i-ion-pricetag" />
    <HomeCard to="/account/favorites" :label="t('favorites')" icon="i-ion-star" />
    <HomeCard to="/wishlists" :label="t('wishlists')" icon="i-fa6-solid-list-check" />
    <HomeCard to="/account/orders" :label="t('orders')" icon="i-ion-cart" />
  </div>
  <div v-if="mdAndLarger" class="mt-4 md:mt-5 md:mx-5 hidden md:block">
    <picture class="w-full h-auto pointer-events-none">
      <source v-for="s in headerImageSrcset.sources" :key="s.srcset" :type="s.type" :srcset="s.srcset">
      <img class="pointer-events-none" :src="headerImageSrc" alt="" :height="headerImageHeight" :width="headerImageWidth">
    </picture>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
</route>

<style lang="scss" scoped>
.card {
  padding: 10px;
  background: var(--secondary);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0px;
  box-shadow: none;
  transition: all 0.125s ease-in-out;

  ion-card-content {
    font-size: 14px;
    color: var(--primary);
    font-weight: bold;

    @media screen and (min-width: 767px) {
      font-size: 18px;
    }
  }
}
</style>
