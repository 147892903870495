<script setup lang="ts">
import type { PDFPageProxy } from 'pdfjs-dist'

const props = defineProps({
  page: null
})

const renderScale = ref(3)
const displayScale = ref(2)

const viewport = computed(() => props.page?.getViewport({ scale: renderScale.value }))

const canvas = ref<HTMLCanvasElement | null>(null)

const ctx = computed(() => canvas.value?.getContext('2d') ?? null)

watchEffect(() => {
  if (props.page && ctx.value && viewport.value) {
    props.page.render({
      canvasContext: ctx.value,
      viewport: viewport.value,
    })
  }
})
</script>

<template>
  <canvas
    ref="canvas" :height="viewport.height" :width="viewport.width" class="max-w-full" :style="{
      width: `${(viewport.width / renderScale) * displayScale}px`,
    }"
  />
</template>
