<script setup lang="ts">
// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,

// they will be rendered correctly in the html results with vite-ssg
const { t } = useI18n()

// const userStore = useUser()

// let googleAnalyticsIsInitialized = false

// const googleAnalyticsId: string = import.meta.env.VITE_GA_ID

// watchEffect(() => {
//   if (userStore.isLoggedIn && !googleAnalyticsIsInitialized && googleAnalyticsId) {
//     googleAnalyticsIsInitialized = true
//     const script = document.createElement('script')
//     script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
//     document.head.appendChild(script);
//     (window as any).dataLayer = (window as any).dataLayer || []
//     function gtag(...args: any[]) {
//       (window as any).dataLayer.push(args)
//     }
//     gtag('js', new Date())
//     gtag('config', googleAnalyticsId, { debug_mode: true })
//   }
// })

useHead({
  title: 'Schäfer Tapeten',
  meta: [
    { name: 'description', content: t('head_content') },
    {
      name: 'theme-color',
      content: computed(() => '#b51d28'),
    },
  ],
})
</script>

<template>
  <RouterView />
</template>
