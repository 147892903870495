<script setup lang="ts">
const props = defineProps({
  label: null,
  icon: null,
  to: null
})
</script>

<template>
  <RouterLink :to="props.to" class="block flex items-center justify-between flex-col bg-secondary min-h-35 py-5 group hover:bg-neutral-200 transition">
    <div class="text-primary text-5xl group-hover:scale-110 transition" :class="props.icon" />
    <span class="text-primary text-xl font-bold text-center hyphens-manual">{{ props.label }}</span>
  </RouterLink>
</template>
