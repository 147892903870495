import { awaitLoading } from '~/services/loading'
import { type UserModule } from '~/types'

export const install: UserModule = ({ router }) => {
  router.beforeEach(async (to) => {
    if (to.meta?.public)
      return true

    const userStore = useUser()
    const isLoggedIn = userStore.isLoggedIn || await awaitLoading(userStore.checkLogin())
    if (to.name === 'login')
      return isLoggedIn ? { path: '/' } : true

    if (isLoggedIn)
      return true

    return { path: '/login' }
  })
}
