button:
  about: About
  back: Back
  go: GO
  home: Home
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
intro:
  desc: Opinionated Vite Starter Template
  dynamic-route: Demo of dynamic route
  hi: Hi, {name}!
  aka: Also known as
  whats-your-name: What's your name?
not-found: Not found
selectAnOption: please select an option
pleaseWait: Please wait...
