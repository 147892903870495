import * as Globalize from 'globalize'
import type { UserModule } from '~/types'

export const install: UserModule = async () => {
  const imports = [
    import('cldr-data/supplemental/likelySubtags.json'),
    import('cldr-data/supplemental/numberingSystems.json'),
    import('cldr-data/main/de/numbers.json'),
    import('cldr-data/main/de/timeZoneNames.json'),
    import('cldr-data/main/de/ca-gregorian.json'),
    import('cldr-data/supplemental/metaZones.json'),
    import('cldr-data/supplemental/timeData.json'),
    import('cldr-data/supplemental/weekData.json'),
  ]
  const loadetImports = await Promise.all(imports)
  loadetImports.forEach(e => Globalize.load(e.default))

  const timeZones = await import('iana-tz-data/iana-tz-data.json')

  Globalize.loadTimeZone(timeZones.default)
  Globalize.locale('de')
}
