import { twMerge } from 'tailwind-merge'
import type { MaybeRef } from 'vue'
import type { ClassValue } from '~/types'

export function useMergedClasses(...classes: MaybeRef<ClassValue>[]) {
  return computed(() => {
    const classArray = classes
      .map(e => toValue(e))
      .map((e) => {
        if (typeof e === 'string')
          return e
        if (typeof e === 'object' && e !== null)
          return Object.entries(e).map(([key, value]) => value ? key : '').filter(({ length }) => length).join(' ')
        return ''
      })
    return twMerge(...classArray)
  })
}
