export function toNumber<T>(rawValue: any, defaultValue: T): number | T {
  if (typeof rawValue === 'number')
    return rawValue
  if (rawValue === null || rawValue === undefined)
    return defaultValue
  const number = Number.parseFloat(rawValue)
  if (Number.isFinite(number))
    return number
  return defaultValue
}
