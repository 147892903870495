<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <IonGrid>
    <IonRow>
      <IonCol class="nav-col hidden lg:flex justify-end">
        <IonList class="flex items-center">
          <NavItem icon-class="i-ion-pricetag" to="/price" :label="t('price_query')" />
          <NavItem icon-class="i-ion-star" to="/account/favorites" :label="t('favorites')" />
          <NavItem icon-class="i-fa6-solid-list-check" to="/wishlists" :label="t('wishlists')" />
          <NavItem icon-class="i-ion-cart" to="/account/orders" :label="t('orders')" />
          <!-- <NavItem icon-class="i-ion-menu" to="/object" :label="t('objects')" /> -->
        </IonList>
      </IonCol>
    </IonRow>
  </IonGrid>
</template>
