

import __layout_0 from '/builds/scheafer-tapeten/b2b-app/src/layouts/default.vue'
export const layouts = {
'404': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/404.vue'),
'account': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/account.vue'),
'checkout': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/checkout.vue'),
'default': __layout_0,
'empty': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/empty.vue'),
'home': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/home.vue'),
'login': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/login.vue'),
'product': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/product.vue'),
'wishlist': () => import('/builds/scheafer-tapeten/b2b-app/src/layouts/wishlist.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
